import { defineStore } from "pinia";
import notesService from "@/services/modules/notes";
import type {
  NoteFilterParams,
  NotesResource,
  NotePayload
} from "@/models/notes";
import type { IResponseLinks, IResponseMeta } from "@/models/common";

interface UseNotesState {
  currentNotes: NotesResource;
  activeNoteDealId: string | null;
  activeNoteBusinessDealId: number | null;
  isNotePiPModeActivated: boolean;
}
const getDefaultState = (): UseNotesState => ({
  currentNotes: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeNoteDealId: null,
  activeNoteBusinessDealId: null,
  isNotePiPModeActivated: false
});

const useNotesStore = defineStore("notes", {
  state: getDefaultState,
  actions: {
    async getNotes(params: NoteFilterParams) {
      const currentParams: NoteFilterParams = {
        ...params,
        application_ids:
          this.isNotePiPModeActivated && this.activeNoteDealId
            ? [this.activeNoteDealId]
            : params.application_ids
      };
      const notes = await notesService.getNotes(currentParams);
      this.currentNotes = notes.data;
    },
    async createNote(payload: NotePayload) {
      const currentPayload: NotePayload = {
        ...payload,
        notable_id: this.getNotableId(payload.notable_id)
      };
      await notesService.createNote(currentPayload);
    },
    async updateNote(payload: NotePayload) {
      const currentPayload: NotePayload = {
        ...payload,
        notable_id: this.getNotableId(payload.notable_id)
      };
      if (!payload.id) {
        return;
      }
      await notesService.updateNote(currentPayload);
    },
    resetNotes() {
      if (this.isNotePiPModeActivated) {
        return;
      }
      this.currentNotes = getDefaultState().currentNotes;
    },

    getNotableId(notableId: string | number | undefined) {
      if (!this.isNotePiPModeActivated) {
        return notableId;
      }

      return (
        this.activeNoteBusinessDealId ?? this.activeNoteDealId ?? undefined
      );
    }
  }
});

export default useNotesStore;
